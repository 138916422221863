import * as React from "react"
import Layout from "../components/layout"
import Government from "../components/Industries/Government"






const government = () => {
  return (
  <Layout>
   <Government/>
  </Layout>
)
};
export default government;
