import React from "react"
import { Link } from "gatsby"
// import quality from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
// import speed from "../../assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp"
// import security from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
import governmentbanner from "../../assets/images/banners/new-banners/government.jpg"
import industryimage from "../../assets/images/banners/industry-images/government.jpg"

import quality from '../../assets/images/banners/icon-pack/quality.png'
import speed from '../../assets/images/banners/icon-pack/rocket.png'
import security from '../../assets/images/banners/icon-pack/secure-shield.png'

// import "../../assets/css/style.css"
// import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const Government = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img government"
          data-bg={governmentbanner}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Government</h1>
                  <h5 className="font-weight--normal text-white">
                    Our team is experienced in delivering projects in various
                    ministries in governments. We've been at the forefront of
                    offering business solutions to the transportation and health
                    sector by combining our substantial industry knowledge with
                    cutting-edge technologies.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our Industry Solution</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Government <span className="text-color-primary">Sector</span></h2>
                  We provide the most cost-effective, high-return
                  approach for training your AI models with the most
                  varied, extensible labelling options across data
                  types, languages and dialects, and security
                  requirements. From white-glove managed service to
                  flexible self-service, you just choose the level of
                  service and security you desire.
                  <br />
                  So you can focus on driving your goal, we simplify the
                  complexities of secure multi-cloud-as-a-service while
                  also addressing compliance obligations across the
                  cloud lifespan. Aamanto Government Solution offers
                  unrivaled multi-cloud, security, and compliance
                  experience, enabling public sector organizations and
                  their partners to reliably design, deploy, manage, and
                  optimize cloud environments.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={industryimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============--> */}

        {/* <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">What <span className="text-color-primary">We Provide</span></h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div
                          className="image-box-wrap"
                          style={{ height: "575px" }}
                        >
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={quality} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Quality</h5>
                            <div className="text">
                              Our project management skills employ various
                              quality control methods and mechanisms to meet and
                              surpass quality standards for training data.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Speed</h5>
                            <div className="text">
                              Our platform and services were created with the
                              goal of handling large-scale data collecting and
                              annotation projects on demand. We optimize
                              productivity and can swiftly ramp up new projects
                              in new areas due to our deep expertise, planning,
                              and recruiting to satisfy a variety of use cases.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div
                          className="image-box-wrap"
                          style={{ height: "575px" }}
                        >
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Security</h5>
                            <div className="text">
                              Government agencies, police forces, and municipal
                              governments entrust us with handling and providing
                              secure data services. Our protected facilities are
                              accredited, ensuring that your data is kept safe
                              and secure.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-1-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={quality} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Quality</h5>
                            <div className="v1-text-2">
                              Our project management skills employ various quality control methods and mechanisms to meet and surpass quality standards for training data.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-1-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={speed} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Speed</h5>
                            <div className="v1-text-2">
                              Our platform and services were created with the goal of handling large-scale data collecting and annotation projects on demand. We optimize productivity and can swiftly ramp up new projects.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-1-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={security} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Security</h5>
                            <div className="v1-text-2">
                              Government agencies, police forces, and municipal governments entrust us with handling and providing secure data services. Our protected facilities are ensuring that your data is kept safe.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* <!--===========  feature-large-images-wrapper  End =============--> */}
      </div>
    </div>
  )
}

export default Government
